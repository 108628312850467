<template>
  <section class="online-course-details">
    <!-- <div id="header" class="tabs-slider" >
        <VueSlickCarousel v-bind="settings">
          <div><h1>e</h1></div>
          <div><h1>e</h1></div>
        </VueSlickCarousel>
      </div> -->
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="generic-wizard-heading">Select Your Course</div>
        </div>
      </div>
      <!-- <div class="tabouter" v-if="tagsFilters.length > 1">
        <div class="wizard3 mt-5 scroll-bar" id="tag-filter">
          <div class="tabwrapper">
            <div class="tabItem" v-for="item in tagsFilters" :key="item.TagId">
              <label class="labelContainer" :class="selected.find((el) => el === item.TagId) === item.TagId ? 'selectedTag' : ''"
                >{{ item.TagName }}
                <input type="checkbox" :value="item.TagId" v-model="selected" @change="selectedValues" :disabled="sendingRequest" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div> -->


      <div class="tabs-slider" v-if="tagsFilters.length > 1">
        <div class="wizard3 mt-5 " id="tag-filter">
          <ssr-carousel :slides-per-page='null'>
            <div class="tabwrapper">
              <div class="tabItem" v-for="item in tagsFilters" :key="item.TagId">
                <label class="labelContainer"
                  :class="selected.find((el) => el === item.TagId) === item.TagId ? 'selectedTag' : ''">{{ item.TagName
                  }}
                  <input type="checkbox" :value="item.TagId" v-model="selected" @change="selectedValues"
                    :disabled="sendingRequest" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </ssr-carousel>
        </div>
      </div>




      <skeleton-loader id="examCoursesLoader" :count="3" :showEmptyTemplate="true" :defaultLoadingProp="true">
        <template v-slot:default="{ isLoading }">
          <div class="generic-layout-cntr" v-if="courseList.length !== 0">
            <template v-for="(course, index) in courseList">
              <div v-if="!course.IsFree" class="col-lg-4 col-md-6 mb-40 special" :key="index">
                <course-card :model="course" :programCategoryId="programCategoryId" />
              </div>
            </template>
          </div>
          <no-record-template v-else-if="isLoading && courseList.length == 0"></no-record-template>
          <div class="load-more">
            <a v-if="canCoursesLoadMore && isLoading" id="btnLoadMore" @click="loadMore" href="javascript:void(0)"
              class>
              <i class="far fa-chevron-down"></i>
              <div class="loadmore-separator"></div>
            </a>
          </div>
        </template>
      </skeleton-loader>
    </div>
    <cartConfirmationModal />
  </section>
</template>
<script>
import { appConstants } from "../../../common/constants";
import { mapGetters, mapActions } from "vuex";
import { classCourses as classCoursesStoreKeys, course as courseStoreKeys } from "../../../store/keys";
import courseTemplate from "../courseTemplate.vue";
import courseCard from "../../../components/courseCard.vue";
import breadcrumb from "../breadcrumb.vue";
import cartConfirmationModal from "../cartConfirmationModal.vue";
import SsrCarousel from "vue-ssr-carousel";
import ssrCarouselCss from "vue-ssr-carousel/index.css";
export default {
  components: {
    courseTemplate,
    breadcrumb,
    SsrCarousel,
    cartConfirmationModal,
    courseCard,
  },
  mounted() {
    //this.resetClassCourses();
    this.getClassCourses();
  },
  computed: {
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    ...mapGetters(classCoursesStoreKeys.namespace, classCoursesStoreKeys.getters),
  },
  data: function () {
    return {
      coursePaging: {
        pageNumber: 1,
        pageSize: 12,
      },
      showRecordsNotFound: false,
      programCategoryId: appConstants.enums.programCategory.Exam,
      selected: [],
      timeoutId: null,
      sendingRequest: false,
    };
  },
  beforeDestroy() { },
  destroyed() {
    this.reset_TagsFilter();
    //this.resetClassCourses();
  },
  methods: {
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    ...mapActions(classCoursesStoreKeys.namespace, classCoursesStoreKeys.actions),
    courseSubscriptionType() {
      let subscriptionType = this.$route.name === "FreeTrialExam" ? appConstants.enums.subscriptionType.Free : appConstants.enums.subscriptionType.All;
      return subscriptionType;
    },
    selectedValues(event) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      if (event.target.checked && event.target.value === "-1") {
        let selectAllFilters = this.tagsFilters.map((el) => ({
          ...el,
          IsDefaultSelected: true,
        }));
        this.selected = selectAllFilters.filter((el) => el.IsDefaultSelected).map((el) => el.TagId);
      } else if (!event.target.checked && event.target.value === "-1") {
        this.selected = [];
      } else if (!event.target.checked && event.target.value !== "-1") {
        let index = this.selected.indexOf(-1);
        if (index !== -1) this.selected.splice(this.selected.indexOf(-1), 1);
      }
      if (this.selected.length) {
        this.coursePaging.pageNumber = 1;
        this.timeoutId = setTimeout(() => {
          this.getClassCourses(null, this.selected);
        }, 1000);
      }
    },
    getClassCourses(pagingMode, selectedTags) {
      this.get_ClassCourses({
        data: {
          AcademicProgramId: this.selectedSOSCategoryAcademicProgram.id,
          CourseGroupId: this.selectedSOSCategory.id,
          ProgramCategoryId: appConstants.enums.programCategory.Exam,
          SubscriptionType: this.courseSubscriptionType(),
          PageNumber: this.coursePaging.pageNumber,
          PageSize: this.coursePaging.pageSize,
          ApplyDefaultTagFilter: true,
          ...(selectedTags && selectedTags.length && { TagIds: [...selectedTags] }),
        },
        config: {
          loader: {
            show: true,
            id: "examCoursesLoader",
          },
        },
        pagingMode: pagingMode,
      })
        .then((response) => {
          this.sendingRequest = false;
          this.showRecordsNotFound = true;
          this.selected = this.tagsFilters.filter((el) => el.IsDefaultSelected).map((el) => el.TagId);
          this.coursePaging.pageNumber = response.PageNumber;
        })
        .catch((ex) => {
          this.sendingRequest = false;
          this.$alertError(ex.message);
        });
    },
    resetClassCourses() {
      this.reset_ClassCourses().then((response) => {
        this.getClassCourses();
      });
    },
    updateSelectedSOS(course) {
      this.update_SelectedSOS({
        data: { id: course.SOSId, name: course.SOSName },
        config: {
          loader: {
            show: false,
          },
        },
      })
        .then((response) => {
          // this.$router.push(
          //   "/class/detail/9th-25/punjab/engineering?s=" +
          //     this.selectedCourseInfo.sos.id +
          //     "&b=" +
          //     this.selectedCourseInfo.board.id +
          //     "&g=" +
          //     this.selectedCourseInfo.courseGroup.id
          // );
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
    loadMore() {
      this.coursePaging.pageNumber++;
      this.getClassCourses(appConstants.enums.pagingMode.loadMore, this.selected);
    },
    UpdateNextWizard() {
      this.$router.push("/class/detail/" + this.RemoveSpecialChars(this.selectedCourseInfo.sos.name) + "/" + this.RemoveSpecialChars(this.selectedCourseInfo.board.name) + "/" + this.RemoveSpecialChars(this.selectedCourseInfo.courseGroup.name) + "?s=" + this.selectedCourseInfo.sos.id + "&b=" + this.selectedCourseInfo.board.id + "&g=" + this.selectedCourseInfo.courseGroup.id).catch(() => { });
    },
  },
};
</script>
<style scoped>
/* .wizard3 .ssr-carousel-track{
  justify-content: start;
} */
.loadmore-separator {
  background: #ccc;
  height: 1px;
  width: 70%;
  margin-top: -17px;
  margin: -17px auto 0 auto;
}

.load-more {
  text-align: center;
  cursor: pointer;
}

.load-more i {
  background: #fff;
  padding: 9px 10px;
  border-radius: 50%;
  font-size: 17px;
  box-shadow: 0 0 10px 0px #d8d8d8;
}

.layour-padding {
  padding-top: 25px !important;
}
</style>
