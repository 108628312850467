import { render, staticRenderFns } from "./wizard-step-3.vue?vue&type=template&id=ade15dd4&scoped=true&"
import script from "./wizard-step-3.vue?vue&type=script&lang=js&"
export * from "./wizard-step-3.vue?vue&type=script&lang=js&"
import style0 from "./wizard-step-3.vue?vue&type=style&index=0&id=ade15dd4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ade15dd4",
  null
  
)

export default component.exports